import {
    COLOR_ATTRIBUTE,
    ENGINE_DESCRIPTION_ATTRIBUTE,
    FRAME_MATERIAL_ATTRIBUTE,
    FRAME_SHAPE_ATTRIBUTE,
    FRAME_SHAPE_ATTRIBUTE_2,
    FRAME_SIZE_ATTRIBUTE,
    REAR_DERAILLEUR_DESCRIPTION_ATTRIBUTE,
    WHEEL_SIZE_ATTRIBUTE,
    FRONT_FORK_DESCRIPTION_ATTRIBUTE, //  https://dev.azure.com/accell/Digital/_workitems/edit/99055
} from './attributes';
import { COLOR_GROUP_PARAM, PRICE_AMOUNT_PARAM } from './filters';
import { UIConfig, UIPage, UISiteConfig, UITheme } from './uiConfig';
import { AttributeOption } from '../types/productVariant';
import { HAIBIKE_FRAME_SIZE_ATTRIBUTE } from './index';

const config: UIConfig = {
    header: {
        darkTheme: new Set([]),
    },
    footer: {
        darkTheme: new Set([
            UIPage.Home,
            UIPage.Basket,
            UIPage.Category,
            UIPage.Product,
            UIPage.ProductLine,
            UIPage.ProductLineListing,
            UIPage.PublicationListing,
            UIPage.Publication,
            UIPage.CampaignListing,
            UIPage.Campaign,
            UIPage.NotFound,
            UIPage.StoreLocator,
            UIPage.BikeComparison,
        ]),
    },
};

export const uiSiteConfig: UISiteConfig = {
    defaultHrefLang: 'de-de',
    awinEnabled: true,
    urlStructure: {
        productListing: {
            urlSegmentation: {
                enabled: false,
            },
        },
        productDetail: {
            path: {
                parentProduct: [{ productKey: 'name' }, { productKey: 'sku' }],
            },
            urlSegmentation: {
                enabled: true,
            },
        },
        productLineDetail: {
            urlSegmentation: {
                enabled: true,
            },
        },
        localeSeparator: '-',
    },
    fonts: {
        urlFile: ['/fonts/fonts.css'],
    },
    Product: {
        attributesKeyMap: {
            default: {
                [COLOR_ATTRIBUTE]: 'COLORCOM',
                [FRAME_SHAPE_ATTRIBUTE]: 'UB-FRAMEKI',
                [FRAME_SHAPE_ATTRIBUTE_2]: 'FRAMETYPE',
                [FRAME_SIZE_ATTRIBUTE]: 'HEIGHTS',
                [WHEEL_SIZE_ATTRIBUTE]: 'Wheel Size',
                [FRAME_MATERIAL_ATTRIBUTE]: 'Frame material',
                [REAR_DERAILLEUR_DESCRIPTION_ATTRIBUTE]: 'DerailleurRearDescription',
                [ENGINE_DESCRIPTION_ATTRIBUTE]: 'MotorDescription',
                [COLOR_GROUP_PARAM]: 'COLORBAS',
                [FRONT_FORK_DESCRIPTION_ATTRIBUTE]: 'FrontForkDescription', //  https://dev.azure.com/accell/Digital/_workitems/edit/99055
            },
        },
        optionsDisplayRules: [
            {
                attributeKeys: ['UB-FRAMEKI', 'FRAMETYPE'],
                priority: 1,
                force: true,
            },
            {
                attributeKeys: ['WheelsizeDescription', 'WHEEL'],
                priority: 2,
                force: true,
            },
            {
                attributeKeys: ['HEIGHTS'],
                priority: 3,
                force: true,
            },
            {
                attributeKeys: ['COLORCOM'],
                priority: 4,
                force: true,
            },
        ],
        breadcrumbsDepth: 2,
        showIconsForSingleProduct: ['UB-FRAMEKI', 'FRAMETYPE'],
        smartfit: {
            visible: true,
            sizeAttribute: HAIBIKE_FRAME_SIZE_ATTRIBUTE as AttributeOption,
            formatSizeValue: true,
        },
        geometry: {
            visible: true,
        },
    },
    ProductListing: {
        categoryPageSize: 20,
        readMoreButtonWithTitle: false,
        columns: {
            xxl: 2,
        },
        filters: {
            default: [
                'HEIGHTS',
                'UB-MRS',
                'UB-FRAMEKI', // Frame type
                'WHEEL', // Wheel size
                PRICE_AMOUNT_PARAM, // Price
                'FRAMEMAT', // Frame material
                'COLORBAS', // Color basic
                'UB-MTBRD', // Drive unit brand
                'UB-BATCAP', // Battery capacity
                'UB-GRNBR', // Number of gears
            ],
        },
    },
    OCampaignListXL: {
        showBackgroundVideo: true,
    },
    OProductFull: {
        hero: {
            visible: false,
            theme: UITheme.Light,
        },
        reviews: {
            visible: true,
            exclude: ['DE', 'AT', 'CH', 'CZ', 'ES', 'FR', 'BE', 'IT', 'PL', 'FI', 'SE', 'IE', 'NL'],
        },
        yotpoGallery: {
            visible: true,
        },
        images: {
            layout: 'carousel',
            containImages: false,
        },
        config: {
            theme: UITheme.Light,
            preferOnlineSales: true,
            oneyPriceRange: {},
        },
        description: {
            theme: UITheme.Light,
        },
        keyFeatures: {
            visible: true,
            theme: UITheme.Light,
            direction: 'horizontal',
            accordion: true,
        },
        geometry: {
            theme: UITheme.Light,
        },
        specifications: {
            theme: UITheme.Light,
        },
        relatedProducts: {
            theme: UITheme.Light,
        },
        flowboxGallery: {
            visible: false,
        },
        bidex: {
            default: false,
            // de: true,
            // at: true,
            // ch: true,
        },
    },
    ProductConfiguratorOptions: {
        justifyOptionLayout: false,
    },
    Campaign: {
        theme: UITheme.Light,
        fullSizeHero: true,
    },
    ProductLine: {
        theme: UITheme.Light,
    },
    ProductLineListing: {
        theme: UITheme.Light,
    },
    SplashPage: {
        displayExtendedVersion: true,
        theme: UITheme.Light,
    },
    Dealer: {
        hasDetailPage: false,
    },
    Home: {
        theme: UITheme.Light,
        yotpoGallery: {
            visible: true,
        },
    },
    Header: {
        centeredLogo: false,
    },
    StoreLocator: {
        filters: {
            default: [],
        },
        filterByType: [],
        theme: UITheme.Light,
    },
    Checkout: {
        shippingLogic: {
            default: undefined,
        },
    },
    Basket: {
        disableQuantitySelection: false,
    },
    OBrandSignature: {
        // TODO: add a shape for raleigh
    },
    FullSizeBannerContent: {
        contentAlignmentConfig: 'leftMid', // This is overridden by the component's own contentAlignment prop from Storyblok
        fluidContainer: true,
        largeHeading: false,
    },
    PublicationListingContent: {
        textAlign: 'center',
    },
    BikeComparison: {
        theme: UITheme.Light,
    },
    ContactMethodsListingPage: {
        theme: UITheme.Light,
    },
    ContactMethodPage: {
        theme: UITheme.Light,
    },
    ShippingMethodsListingPage: {
        theme: UITheme.Light,
    },
    ShippingMethodPage: {
        theme: UITheme.Light,
    },
    PaymentMethodsListingPage: {
        theme: UITheme.Light,
    },
    PaymentMethodPage: {
        theme: UITheme.Light,
    },
    CookieSettings: {
        recaptcha: {
            oneTrustKeysToValidate: { default: ['C0001'] },
        },
        googleMaps: {
            oneTrustKeysToValidate: { default: ['C0001', 'C0003'] },
        },
        youtube: {
            oneTrustKeysToValidate: { default: ['C0001', 'C0003'] },
        },
        oneTrustAutoblockEnabled: true,
    },
};

export default config;
