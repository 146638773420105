// Generated file, do not edit.
// Edit the json files in the designTokens directory and run the build-design-tokens.js script.

export default {
    optionsBrandColorBlack: '#171717',
    optionsBrandColorOffWhite: '#EFEFEF',
    optionsBrandColorBlue: '#34428A',
    optionsBrandColorBlue2: '#00D8E5',
    optionsBrandColorLimboGradient: 'linear-gradient(180deg, #B8B8B8 0%, #6F6F6F 100%)',
    optionsBrandColorGrey: '#9A9B9D',
    optionsBrandColorColor3: '#0E74BA',
    optionsBrandColorColor4: '#2989F1',
    optionsBrandColorColor5: '#007B4D',
    optionsBrandColorColor6: '#20A954',
    optionsBrandColorColor7: '#63E761',
    optionsBrandColorColor8: '#F192BC',
    optionsBrandColorColor9: '#E74033',
    optionsBrandColorColor10: '#D0A045',
    optionsBrandColorColor11: '#F0CA00',
    optionsBrandColorColor12: '#FFEB3B',
    optionsBrandColorIndigoBlue: '#34428A',
    optionsBrandColorAzureBlue: '#0E74BA',
    optionsBrandColorForestGreen: '#007B4D',
    optionsBrandColorParakeetGreen: '#20A954',
    optionsBrandColorCrimsonRed: '#E74033',
    optionsBrandColorPunchPink: '#EB5B7F',
    optionsBrandColorPearlPink: '#F192BC',
    optionsBrandColorHoneyYellow: '#F0CA00',
    optionsBrandColorPumpkinOrange: '#F0821F',
    optionsBrandColorGreen: '#007B4D',
    optionsBrandColorRed: '#E74033',
    optionsBrandColorYellow: '#D0A045',
    optionsBrandColorWhite: '#FFFFFF',
    optionsBrandColorXlcSand: '#A1A1A1',
    optionsBrandColorXlcGrey: '#6A798C',
    optionsBrandColorBlueGradient: 'linear-gradient(180deg, #002AB0 0%, #002495 100%)',
    optionsBrandColorGreenGradient: 'linear-gradient(180deg, #019765 0%, #007B4D 100%)',
    optionsBrandColorYellowGradient: 'linear-gradient(180deg, #FCD200 0%, #FCC800 100%)',
    optionsBrandColorXlcBlue: '#009fe3',
    optionsBrandColorXlcBlueButton: '#008FCC',
    optionsBrandColorXlcBlack: '#171717',
    optionsBrandColorXlcLimboGradient: 'linear-gradient(180deg, #b8b8b8 0%, #6f6f6f 100%)',
    optionsBrandColorXlcLimbo1: '#b8b8b8',
    optionsBrandColorXlcLimbo2: '#6f6f6f',
    optionsBrandColorXlcBlueHover: '#008FCC',
    optionsBrandColorXlcBlueHoverButton: '#0081B8',
    optionsBrandColorXlcOffWhite: '#EFEFEF',
    optionsBrandColorXlcBlackHover: '#302E2E',
    optionsBrandColorXlcLimbo1Hover: '#919495',
    optionsBrandColorXlcLimbo2Hover: '#646464',
    optionsBrandColorXlcOffWhiteHover: '#E5E5E5',
    optionsNeutralColorBlack: '#000000',
    optionsNeutralColorGrey900: '#121212',
    optionsNeutralColorGrey800: '#181818',
    optionsNeutralColorGrey750: '#1E1E1E',
    optionsNeutralColorGrey700: '#424547',
    optionsNeutralColorGrey600: '#626667',
    optionsNeutralColorGrey500: '#888c8d',
    optionsNeutralColorGrey400: '#b3b5b5',
    optionsNeutralColorGrey300: '#d1d4d2',
    optionsNeutralColorGrey200: '#E3E3E3',
    optionsNeutralColorGrey100: '#F3F3F3',
    optionsNeutralColorGrey50: '#F9F9F9',
    optionsNeutralColorGrey150: '#F8F8F8',
    optionsNeutralColorWhite: '#FFFFFF',
    optionsFormColorCheck: '#21A153',
    optionsStatusColorSuccessLight: '#F0FDF5',
    optionsStatusColorSuccessMain: '#21A153',
    optionsStatusColorSuccessDark: '#21A153',
    optionsStatusColorWarningLight: '#FEFCE9',
    optionsStatusColorWarningMain: '#ffb75d',
    optionsStatusColorWarningDark: '#975A12',
    optionsStatusColorErrorLight: '#FEF3F2',
    optionsStatusColorErrorMain: '#c23934',
    optionsStatusColorErrorDark: '#AE201A',
    optionsStatusColorInfoLight: '#EFF6FF',
    optionsStatusColorInfoMain: '#0073BE',
    optionsStatusColorInfoDark: '#1244CA',
    optionsFontFamilySansSerifOne: "'ABC Favorit'",
    optionsFontFamilySansSerifTwo: "'ABC Favorit'",
    optionsFontFamilySansSerifThree: "'ABC Favorit'",
    optionsFontWeightLight: '300',
    optionsFontWeightRegular: '400',
    optionsFontWeightBold: '500',
    optionsFontWeightExtraBold: '500',
    optionsTextTransformNormal: 'none',
    optionsTextTransformLowercase: 'lowercase',
    optionsTextTransformUppercase: 'uppercase',
    optionsLetterSpacingNormal: '0',
    optionsLetterSpacingWide: '.075em',
    optionsLetterSpacingMediumWide: '.1em',
    optionsLetterSpacingExtraWide: '.125em',
    optionsMediaQueryXs: 576,
    optionsMediaQueryS: 768,
    optionsMediaQueryM: 992,
    optionsMediaQueryL: 1200,
    optionsMediaQueryXl: 1600,
    breakpointExtraSmall: 576,
    breakpointSmall: 768,
    breakpointMedium: 992,
    breakpointLarge: 1200,
    breakpointExtraLarge: 1600,
    fontTitleFamily: "'ABC Favorit'",
    fontTitleWeight: '500',
    fontTitleTransform: 'uppercase',
    fontTitleLetterSpacing: '0',
    fontTitleHighlightFamily: "'ABC Favorit'",
    fontTitleHighlightWeight: '500',
    fontTitleHighlightTransform: 'uppercase',
    fontTitleHighlightLetterSpacing: '0',
    fontHeadlineOneFamily: "'ABC Favorit'",
    fontHeadlineOneWeight: '500',
    fontHeadlineOneTransform: 'uppercase',
    fontHeadlineOneLetterSpacing: '0',
    fontHeadlineTwoFamily: "'ABC Favorit'",
    fontHeadlineTwoWeight: '500',
    fontHeadlineTwoTransform: 'uppercase',
    fontHeadlineTwoLetterSpacing: '0',
    fontHeadlineThreeFamily: "'ABC Favorit'",
    fontHeadlineThreeWeight: '500',
    fontHeadlineThreeTransform: 'uppercase',
    fontHeadlineThreeLetterSpacing: '0',
    fontSubHeadlineFamily: "'ABC Favorit'",
    fontSubHeadlineWeight: '500',
    fontSubHeadlineTransform: 'uppercase',
    fontSubHeadlineLetterSpacing: '0',
    fontTitleOverlayFamily: "'ABC Favorit'",
    fontTitleOverlayWeight: '400',
    fontTitleOverlayTransform: 'uppercase',
    fontTitleOverlayLetterSpacing: '0',
    fontMainNavItemFamily: "'ABC Favorit'",
    fontMainNavItemWeight: '500',
    fontMainNavItemTransform: 'uppercase',
    fontMainNavItemLetterSpacing: '0',
    fontBodyFamily: "'ABC Favorit'",
    fontBodyWeight: '400',
    fontBodyTransform: 'none',
    fontBodyLetterSpacing: '0',
    fontCaptionFamily: "'ABC Favorit'",
    fontCaptionWeight: '400',
    fontCaptionTransform: 'none',
    fontCaptionLetterSpacing: '0',
    fontButtonFamily: "'ABC Favorit'",
    fontButtonWeight: '500',
    fontButtonWeightSmall: '400',
    fontButtonTransform: 'uppercase',
    fontButtonLetterSpacing: '0',
    fontLinkFamily: "'ABC Favorit'",
    fontLinkWeight: '400',
    fontLinkTransform: 'uppercase',
    fontLinkLetterSpacing: '0',
    fontPriceFamily: "'ABC Favorit'",
    fontPriceWeight: '400',
    fontPriceTransform: 'none',
    fontPriceLetterSpacing: '0',
    fontHeroTitleLetterSpacing: '0',
    fontLineHeightExtraSmall: '1.0',
    fontLineHeightSmall: '1.1',
    fontLineHeightMedium: '1.2',
    fontLineHeightLarge: '1.2',
    fontPlpCategoryLabelsTextTransform: 'none',
    fontPlpCategoryLabelsFontWeight: '400',
    fontPlpUpillSortingTextTransform: 'none',
    fontPlpUpillSortingFontWeight: '400',
    sizeTextHeadlineSizeOneSmall: '3.2rem',
    sizeTextHeadlineSizeOneMedium: '4rem',
    sizeTextHeadlineSizeTwoSmall: '2.8rem',
    sizeTextHeadlineSizeTwoMedium: '3.2rem',
    sizeTextHeadlineSizeThreeSmall: '2.4rem',
    sizeTextHeadlineSizeThreeMedium: '2.4rem',
    sizeTextHeadlineSizeFourSmall: '2rem',
    sizeTextHeadlineSizeFourMedium: '2rem',
    sizeTextHeadlineSizeFiveSmall: '1.8rem',
    sizeTextHeadlineSizeFiveMedium: '1.8rem',
    sizeTextHeadlineSizeSixSmall: '1.6rem',
    sizeTextHeadlineSizeSixMedium: '1.6rem',
    sizeTextOverlayTitleExtraSmall: 'calc((1rem + 0.25vw) * 2.2)',
    sizeTextOverlayTitleSmall: 'calc((1rem + 0.25vw) * 4)',
    sizeTextOverlayTitleMedium: 'calc((1rem + 0.25vw) * 5.6)',
    sizeTextOverlayTitleLarge: 'calc((1rem + 0.25vw) * 7.2)',
    sizeTextBodyExtraSmall: '1.2rem',
    sizeTextBodySmall: '1.6rem',
    sizeTextBodyMedium: '1.6rem',
    sizeTextBodyLarge: '1.8rem',
    sizeTextBodyExtraLarge: '2.4rem',
    sizeGridSpacingBlockMedium: '4rem',
    sizeGridSpacingBlockLarge: '8rem',
    sizeGridSpacingRem2: '0.2rem',
    sizeGridSpacingRem4: '0.4rem',
    sizeGridSpacingRem6: '0.6rem',
    sizeGridSpacingRem8: '0.8rem',
    sizeGridSpacingRem10: '1rem',
    sizeGridSpacingRem12: '1.2rem',
    sizeGridSpacingRem14: '1.4rem',
    sizeGridSpacingRem16: '1.6rem',
    sizeGridSpacingRem20: '2rem',
    sizeGridSpacingRem24: '2.4rem',
    sizeGridSpacingRem28: '2.8rem',
    sizeGridSpacingRem32: '3.2rem',
    sizeGridSpacingRem36: '3.6rem',
    sizeGridSpacingRem40: '4rem',
    sizeGridSpacingRem44: '4.4rem',
    sizeGridSpacingRem48: '4.8rem',
    sizeGridSpacingRem52: '5.2rem',
    sizeGridSpacingRem56: '5.6rem',
    sizeGridSpacingRem60: '6rem',
    sizeGridSpacingRem64: '6.4rem',
    sizeGridLayoutRem24: '2.4rem',
    sizeGridLayoutRem32: '3.2rem',
    sizeGridLayoutRem40: '4rem',
    sizeGridLayoutRem48: '4.8rem',
    sizeGridLayoutRem56: '5.6rem',
    sizeGridLayoutRem64: '6.4rem',
    sizeGridLayoutRem72: '7.2rem',
    sizeGridLayoutRem80: '8rem',
    sizeGridLayoutRem88: '8.8rem',
    sizeGridLayoutRem96: '9.6rem',
    sizeGridLayoutRem104: '10.4rem',
    sizeGridLayoutRem120: '12rem',
    sizeGridLayoutMaxWidthXxs: '32rem',
    sizeGridLayoutMaxWidthXs: '37.5rem',
    sizeGridLayoutMaxWidthS: '37.5rem',
    sizeGridLayoutMaxWidthM: '61.6rem',
    sizeGridLayoutMaxWidthL: '86.4rem',
    sizeGridLayoutMaxWidthXl: '160rem',
    sizeGridLayoutMaxWidthXxl: '160rem',
    sizeOtherIconXxs: '1.2rem',
    sizeOtherIconXs: '1.6rem',
    sizeOtherIconS: '2rem',
    sizeOtherIconM: '2.4rem',
    sizeOtherIconL: '3.2rem',
    sizeOtherIconXl: '4rem',
    sizeOtherBorderRadiusS: '0rem',
    sizeOtherBorderRadiusM: '0rem',
    sizeOtherBorderRadiusL: '0.4rem',
    sizeOtherBorderRadiusFull: '99rem',
    sizeOtherBorderRadiusButton: '0rem',
    sizeOtherBorderWidthS: '0.1rem',
    sizeOtherBorderWidthXs: '0.1rem',
    sizeOtherLogoWidth: '15.8rem',
    sizeOtherLogoHeight: '1.6rem',
    svgRatingsStarPath:
        'M5.29912851,4.57520277 L0.552479625,5.25223982 L0.47915698,5.26674626 C0.00513697679,5.38775526 -0.168956388,5.98274547 0.195319258,6.33131796 L3.63001442,9.61792143 L2.81928166,14.2590186 L2.81088573,14.3286826 C2.77905682,14.8103298 3.30304684,15.1588353 3.75433964,14.9259255 L8,12.7349048 L12.2456604,14.9259255 L12.3105574,14.9552915 C12.7673486,15.133845 13.2669076,14.7523294 13.1807183,14.2590186 L12.3693411,9.61792143 L15.8046807,6.33131796 L15.8553141,6.277274 C16.166349,5.90586074 15.9509369,5.3240497 15.4475204,5.25223982 L10.700227,4.57520277 L8.57789761,0.352651397 C8.34150615,-0.117550466 7.65849385,-0.117550466 7.42210239,0.352651397 L5.29912851,4.57520277 Z',
    svgRatingsStarViewBox: '0 0 16 15',
    svgRatingsStarRatedColor: '#F0CA00',
    svgRatingsStarEmptyColor: '#E3E3E3',
    svgRatingsStarWidgetSpacing: '0.2rem',
    svgQuoteIconPath:
        'M64.3644 5.34058e-05L62.1307 13.0398C61.4868 16.7425 60.1385 20.5256 58.086 24.3893C55.9932 28.2529 53.4778 31.8952 50.5398 35.3161C47.5616 38.737 44.4626 41.5945 41.2429 43.8885L31.1009 37.1876C33.9584 33.6459 36.4335 29.9432 38.5263 26.0796C40.6191 22.216 42.0479 17.9096 42.8125 13.1606L45.0462 5.34058e-05H64.3644ZM33.8779 5.34058e-05L31.6442 13.0398C31.0003 16.7425 29.652 20.5256 27.5995 24.3893C25.5067 28.2529 22.9913 31.8952 20.0533 35.3161C17.0751 38.737 13.9761 41.5945 10.7564 43.8885L0.614384 37.1876C3.47186 33.6459 5.947 29.9432 8.03981 26.0796C10.1326 22.216 11.5613 17.9096 12.326 13.1606L14.5597 5.34058e-05H33.8779Z',
    svgQuoteIconViewBox: '0 0 65 44',
    svgQuoteIconWidth: 65,
    svgQuoteIconHeight: 44,
    svgQuoteIconFill: '#000000',
    svgMyAccountPath:
        'M5.517,6.252q.338.364.628.7l4.656.549,5.63,2.072V14.16c0,1.871-5.61,3.339-7.184,3.7L4.83,16.236c-.193.237-.364.449-.5.623L5,18.628,6.462,21.6c3.514-.962,10.711-2.552,13.606-4.572,2.76-1.926,3.481-5.095,2.962-8.277-1.037-6.358-3.978-7.509-10.157-7.305C9.666,1.546,4.617,3,1.529,4c0,.247.035.385.035.493,1.935.039,2.035.113,3.97.176M7.29,8.525a4.555,4.555,0,0,1,.663,1.95c.141,1.782-1.079,3.317-2.161,4.6L9.341,16.38c1.037-.248,5.665-1.4,5.665-2.221V10.57L10.469,8.9,7.29,8.525Zm-3.2-2.54c-.961-.031-1.915-.076-2.878-.076a5.252,5.252,0,0,1-1.07-.044C.1,5.143,0,3.908,0,3.145c0-.12.653-.361,1.095-.5C4.325,1.6,9.468.131,12.828.02c6.923-.228,10.454,1.438,11.606,8.5.61,3.743-.324,7.418-3.552,9.67C17.39,20.624,10,22.1,5.7,23.276L2.535,16.828c1.164-1.5,4.136-4.474,4-6.241C6.424,9.243,4.979,7.752,4.091,6.813V5.985Z',
    svgMyAccountViewBox: '0 0 24.609 23.275',
    svgMyAccountWidth: 24.609,
    svgMyAccountHeight: 23.275,
    svgMyAccountFill: 'currentColor',
    svgCartPath:
        'M19.896,7.79423077 L15.44,0 L13.36,1.18730769 L17.136,7.79423077 L6.864,7.79423077 L10.64,1.18730769 L8.56,0 L4.104,7.79423077 L0,7.79423077 L0,10.2173077 L1.44,10.2173077 L3.632,20.0630769 C3.75840467,20.611453 4.24233195,20.9996402 4.8,21 L19.2,21 C19.7576681,20.9996402 20.2415953,20.611453 20.368,20.0630769 L22.56,10.2011538 L24,10.2011538 L24,7.77807692 L19.896,7.79423077 Z M8.4,17.3976923 L6,17.3976923 L6,11.3965385 L8.4,11.3965385 L8.4,17.3976923 Z M13.2,17.3976923 L10.8,17.3976923 L10.8,11.3965385 L13.2,11.3965385 L13.2,17.3976923 Z M18,17.3976923 L15.6,17.3976923 L15.6,11.3965385 L18,11.3965385 L18,17.3976923 Z',
    svgCartViewBox: '0 0 24 21',
    svgCartWidth: 24,
    svgCartHeight: 21,
    svgCartFill: 'currentColor',
    svgSearchPath:
        'M6.47619048,0 C10.0528917,0 12.952381,2.89948924 12.952381,6.47619048 C12.952381,8.08184217 12.3680501,9.55101606 11.4004383,10.6826621 C11.6006024,10.6451975 11.8114518,10.6783226 11.9945136,10.782419 L12.1007085,10.8533965 L12.1840949,10.9270162 L15.7396505,14.4825718 C16.0867832,14.8297045 16.0867832,15.3925178 15.7396505,15.7396505 C15.4507072,16.0285937 15.0081634,16.079327 14.6517434,15.8690295 L14.5476409,15.7980865 L14.4825718,15.7396505 L10.9270162,12.1840949 C10.713477,11.9705557 10.6312967,11.6754005 10.6804755,11.3990417 C9.55101606,12.3680501 8.08184217,12.952381 6.47619048,12.952381 C2.89948924,12.952381 0,10.0528917 0,6.47619048 C0,2.89948924 2.89948924,0 6.47619048,0 Z M6.47619048,1.77777778 C3.88132879,1.77777778 1.77777778,3.88132879 1.77777778,6.47619048 C1.77777778,9.07105216 3.88132879,11.1746032 6.47619048,11.1746032 C9.07105216,11.1746032 11.1746032,9.07105216 11.1746032,6.47619048 C11.1746032,3.88132879 9.07105216,1.77777778 6.47619048,1.77777778 Z',
    svgSearchViewBox: '0 0 16 16',
    svgSearchWidth: 16,
    svgSearchHeight: 16,
    themeColorsPrimaryHeaderNavLink: '',
    themeColorsPrimaryHeaderNavLinkHover: '',
    themeColorsPrimaryHeaderNavLinkActive: '',
    themeColorsPrimaryHeaderNavLinkBackground: '',
    themeColorsPrimaryHeaderNavLinkHoverBackground: '',
    themeColorsPrimaryHeaderNavLinkActiveBackground: '',
    themeColorsPrimaryBackgroundColor: '#171717',
    themeColorsPrimaryBackgroundColorSecondary: '#171717',
    themeColorsPrimaryTextColor: '#FFFFFF',
    themeColorsPrimaryTextColorSecondary: '#009fe3',
    themeColorsPrimaryHeroQuoteTextColor: '#FFFFFF',
    themeColorsPrimaryBorderDividerColor: '',
    themeColorsPrimaryButtonBackgroundColor: '#008FCC',
    themeColorsPrimaryButtonBackgroundColorHover: '#0081B8',
    themeColorsPrimaryButtonTextColor: '#FFFFFF',
    themeColorsPrimaryButtonTextColorHover: '#FFFFFF',
    themeColorsSecondaryHeaderNavLink: '',
    themeColorsSecondaryHeaderNavLinkHover: '',
    themeColorsSecondaryHeaderNavLinkActive: '',
    themeColorsSecondaryHeaderNavLinkBackground: '',
    themeColorsSecondaryHeaderNavLinkHoverBackground: '',
    themeColorsSecondaryHeaderNavLinkActiveBackground: '',
    themeColorsSecondaryBackgroundColor: '#EFEFEF',
    themeColorsSecondaryBackgroundColorSecondary: '#EFEFEF',
    themeColorsSecondaryTextColor: '#171717',
    themeColorsSecondaryTextColorSecondary: '#171717',
    themeColorsSecondaryHeroQuoteTextColor: '#171717',
    themeColorsSecondaryBorderDividerColor: '',
    themeColorsSecondaryButtonBackgroundColor: '#008FCC',
    themeColorsSecondaryButtonBackgroundColorHover: '#0081B8',
    themeColorsSecondaryButtonTextColor: '#FFFFFF',
    themeColorsSecondaryButtonTextColorHover: '#FFFFFF',
    themeColorsTertiaryHeaderNavLink: '',
    themeColorsTertiaryHeaderNavLinkHover: '',
    themeColorsTertiaryHeaderNavLinkActive: '',
    themeColorsTertiaryHeaderNavLinkBackground: '',
    themeColorsTertiaryHeaderNavLinkHoverBackground: '',
    themeColorsTertiaryHeaderNavLinkActiveBackground: '',
    themeColorsTertiaryBackgroundColor: '#008FCC',
    themeColorsTertiaryBackgroundColorSecondary: '#008FCC',
    themeColorsTertiaryTextColor: '#FFFFFF',
    themeColorsTertiaryTextColorSecondary: '#171717',
    themeColorsTertiaryHeroQuoteTextColor: '#171717',
    themeColorsTertiaryBorderDividerColor: '',
    themeColorsTertiaryButtonBackgroundColor: '#EFEFEF',
    themeColorsTertiaryButtonBackgroundColorHover: '#E5E5E5',
    themeColorsTertiaryButtonTextColor: '#008FCC',
    themeColorsTertiaryButtonTextColorHover: '#008FCC',
    themeColorsQuaternaryHeaderNavLink: '',
    themeColorsQuaternaryHeaderNavLinkHover: '',
    themeColorsQuaternaryHeaderNavLinkActive: '',
    themeColorsQuaternaryHeaderNavLinkBackground: '',
    themeColorsQuaternaryHeaderNavLinkHoverBackground: '',
    themeColorsQuaternaryHeaderNavLinkActiveBackground: '',
    themeColorsQuaternaryBackgroundColor: 'linear-gradient(180deg, #B8B8B8 0%, #6F6F6F 100%)',
    themeColorsQuaternaryBackgroundColorSecondary:
        'linear-gradient(180deg, #B8B8B8 0%, #6F6F6F 100%)',
    themeColorsQuaternaryTextColor: '#171717',
    themeColorsQuaternaryTextColorSecondary: '#171717',
    themeColorsQuaternaryHeroQuoteTextColor: '#171717',
    themeColorsQuaternaryBorderDividerColor: '',
    themeColorsQuaternaryButtonBackgroundColor: '#008FCC',
    themeColorsQuaternaryButtonBackgroundColorHover: '#0081B8',
    themeColorsQuaternaryButtonTextColor: '#FFFFFF',
    themeColorsQuaternaryButtonTextColorHover: '#FFFFFF',
    lightTheme: {
        actionPrimaryText: '#EFEFEF',
        actionPrimaryTextHover: '#EFEFEF',
        actionPrimaryBackground: '#008FCC',
        actionPrimaryBackgroundHover: '#0081B8',
        actionPrimaryBorder: 'transparent',
        actionPrimaryBorderHover: 'transparent',
        actionSecondaryText: '#EFEFEF',
        actionSecondaryTextHover: '#EFEFEF',
        actionSecondaryBackground: '#171717',
        actionSecondaryBackgroundHover: '#302E2E',
        actionSecondaryBorder: 'transparent',
        actionSecondaryBorderHover: 'transparent',
        actionTertiaryText: '#FFFFFF',
        actionTertiaryTextHover: '#FFFFFF',
        actionTertiaryBackground: '#A1A1A1',
        actionTertiaryBackgroundHover: '#6A798C',
        actionTertiaryBorder: 'transparent',
        actionTertiaryBorderHover: 'transparent',
        actionMonochromeText: '#FFFFFF',
        actionMonochromeBackground: '#171717',
        actionMonochromeBackgroundHover: '#888c8d',
        actionMonochromeBorder: 'transparent',
        actionMonochromeBorderHover: 'transparent',
        actionSecondaryWhiteText: '#FFFFFF',
        actionSecondaryWhiteTextHover: '#FFFFFF',
        actionSecondaryWhiteBackground: '#008FCC',
        actionSecondaryWhiteBackgroundHover: '#0081B8',
        actionSecondaryWhiteBorder: 'transparent',
        actionSecondaryWhiteBorderHover: 'transparent',
        actionSecondaryBlackText: '#FFFFFF',
        actionSecondaryBlackTextHover: '#FFFFFF',
        actionSecondaryBlackBackground: '#171717',
        actionSecondaryBlackBackgroundHover: '#181818',
        actionSecondaryBlackBorder: 'transparent',
        actionSecondaryBlackBorderHover: 'transparent',
        actionOutlineText: '#171717',
        actionOutlineTextHover: '#EFEFEF',
        actionOutlineBackground: 'transparent',
        actionOutlineBackgroundHover: '#171717',
        actionOutlineBorder: '#171717',
        actionOutlineBorderHover: '#171717',
        actionTextLink: '#424547',
        actionTextLinkHover: '#171717',
        actionTextLinkBorder: '#b8b8b8',
        actionHeadlineLink: '#171717',
        actionHeadlineLinkHover: '#181818',
        actionNavLink: '#171717',
        actionNavLinkBackground: '#FFFFFF',
        actionNavLinkHover: '#009fe3',
        actionNavLinkActive: '#009fe3',
        actionNavLinkHoverBackground: '#FFFFFF',
        actionDisabledText: '#FFFFFF',
        actionDisabledBackground: '#d1d4d2',
        actionDisabledBackgroundHover: '#d1d4d2',
        actionDisabledBorder: '#d1d4d2',
        actionDisabledBorderHover: '#d1d4d2',
        actionRadioSquareBackground: '#FFFFFF',
        actionRadioSquareActiveBackground: '#000000',
        actionRadioSquareColor: '#000000',
        actionRadioSquareActiveColor: '#FFFFFF',
        textTitle: '#121212',
        textHeadline: '#171717',
        textSubHeadline: '#171717',
        textTitleOverlay: '#FFFFFF',
        textBody: '#171717',
        textCaption: '#171717',
        textPrice: '#171717',
        formInputLabelText: '#171717',
        formInputFocusLabelText: '#171717',
        formInputInputText: '#171717',
        formInputInputTextDisabled: '#b3b5b5',
        formInputPlaceholderText: '#626667',
        formInputBorder: '#424547',
        formInputBorderFocus: '#171717',
        formInputBorderError: '#c23934',
        formInputCheckbox: '#21A153',
        formInputBackground: '#FFFFFF',
        formInputBackgroundDisabled: '#F9F9F9',
        formRadioEnabled: '#171717',
        formRadioDisabled: '#d1d4d2',
        formRadioBorderDisabled: '#E3E3E3',
        formRadioBorderEnabled: '#d1d4d2',
        formRadioBorderActive: '#171717',
        formListItemBackground: '#FFFFFF',
        formListItemBackgroundHover: '#FFFFFF',
        formSliderVerticalBars: '#009fe3',
        formSliderHandleBackground: '#FFFFFF',
        formSliderHandleBorder: '#171717',
        formSliderProgressBar: '#171717',
        formStatusErrorLight: '#FEF3F2',
        formStatusErrorMain: '#c23934',
        formStatusErrorDark: '#AE201A',
        formStatusSuccessLight: '#F0FDF5',
        formStatusSuccessMain: '#21A153',
        formStatusSuccessDark: '#21A153',
        formStatusWarningLight: '#FEFCE9',
        formStatusWarningMain: '#ffb75d',
        formStatusWarningDark: '#975A12',
        formStatusInfoLight: '#EFF6FF',
        formStatusInfoMain: '#0073BE',
        formStatusInfoDark: '#1244CA',
        headerBackground: '#FFFFFF',
        headerStickyBorderBottom: 'rgba(227, 227, 227, 0.1)',
        headerStickyBoxShadow: 'rgba(0, 0, 0, 0.03)',
        headerStickyBoxShadowNoNotification: 'rgba(0, 0, 0, 0.25)',
        headerStickyBackground: '#FFFFFF',
        headerStickyBackgroundScrolled: '#FFFFFF',
        headerStickyBorderBottomScrolled: '#E3E3E3',
        headerSearchInputBorder: '#d1d4d2',
        headerMegamenu: '#F9F9F9',
        headerPlpSidebarTitle: '#171717',
        footerBackground: '#FFFFFF',
        footerTextColor: '#171717',
        footerHeadingColor: '',
        tableThead: '#F9F9F9',
        tableTh: '#F9F9F9',
        tableTd: '#FFFFFF',
        iconPrimary: '#171717',
        iconSecondary: '#888c8d',
        iconTertiary: '#b3b5b5',
        iconLoader: '#FFFFFF',
        iconRoundedBackground: '#F3F3F3',
        iconRoundedIcon: '#171717',
        iconRoundedBorder: '#171717',
        iconRoundedActiveBackground: '#171717',
        iconRoundedActiveIcon: '#F3F3F3',
        iconRoundedActiveBorder: '#171717',
        logo: '#171717',
        borderDivider1: '#d1d4d2',
        borderDivider2: '#d1d4d2',
        pillActiveTextColor: '#FFFFFF',
        pillActiveTextColorHover: '#FFFFFF',
        pillActiveBorderColor: '#171717',
        pillActiveBorderColorHover: '#171717',
        pillActiveBackground: '#171717',
        pillActiveBackgroundHover: '#302E2E',
        pillDefaultTextColor: '#171717',
        pillDefaultTextColorHover: '#EFEFEF',
        pillDefaultBorderColor: '#171717',
        pillDefaultBorderColorHover: '#171717',
        pillDefaultBackground: '#FFFFFF',
        pillDefaultBackgroundHover: '#171717',
        pillBadgeBackground: '#FFFFFF',
        pillBadgeBorderColorHover: '#b3b5b5',
        wrapperBody: '#EFEFEF',
        wrapperPublication: '#EFEFEF',
        wrapperCategory: '#EFEFEF',
        wrapperProduct: '#EFEFEF',
        wrapperTileColor: '#FFFFFF',
        wrapperTileColorHover: '#F9F9F9',
        wrapperSection: '#EFEFEF',
        wrapperBodyHover: '#F9F9F9',
        wrapperWell: '#EFEFEF',
        wrapperCard: '#FFFFFF',
        wrapperCardHover: '#F9F9F9',
        wrapperCardGradientColor: '#FFFFFF',
        wrapperCardGradientColorHover: '#F9F9F9',
        wrapperCardBorderColor: 'transparent',
        wrapperModal: '#b3b5b5',
        wrapperBanner: '#FFFFFF',
        wrapperBannerBorder: '#FFFFFF',
        wrapperUsp: '#009fe3',
        wrapperUspColor: '#EFEFEF',
        oBikeComparisonCompareButtonBackground: '#F3F3F3',
        oBikeComparisonCompareButtonBorderColor: '#d1d4d2',
        uListItemHighlightColor: '#009fe3',
        uListItemTextColorHover: '#009fe3',
        uCarouselBackgroundColor: '#FFFFFF',
        uPullUpToggleBackground: '#171717',
        uNewsletterTitleColor: '#EFEFEF',
        uKeyFeatureListBackgroundColor: 'unset',
        fallbackWhite: '#FFFFFF',
        fallbackGrey50: '#F9F9F9',
        fallbackGrey100: '#F3F3F3',
        fallbackGrey200: '#E3E3E3',
        fallbackGrey300: '#d1d4d2',
        fallbackGrey400: '#b3b5b5',
        fallbackGrey500: '#888c8d',
        fallbackGrey600: '#626667',
        fallbackGrey700: '#424547',
        fallbackGrey750: '#1E1E1E',
        fallbackGrey800: '#181818',
        fallbackGrey900: '#121212',
        fallbackBlack: '#171717',
    },
    darkTheme: {
        actionPrimaryText: '#008FCC',
        actionPrimaryTextHover: '#008FCC',
        actionPrimaryBackground: '#EFEFEF',
        actionPrimaryBackgroundHover: '#E5E5E5',
        actionPrimaryBorder: 'transparent',
        actionPrimaryBorderHover: 'transparent',
        actionSecondaryText: '#171717',
        actionSecondaryTextHover: '#171717',
        actionSecondaryBackground: '#EFEFEF',
        actionSecondaryBackgroundHover: '#E5E5E5',
        actionSecondaryBorder: 'transparent',
        actionSecondaryBorderHover: 'transparent',
        actionTertiaryText: '#FFFFFF',
        actionTertiaryTextHover: '#FFFFFF',
        actionTertiaryBackground: '#A1A1A1',
        actionTertiaryBackgroundHover: '#D0A045',
        actionTertiaryBorder: 'transparent',
        actionTertiaryBorderHover: 'transparent',
        actionMonochromeText: '#FFFFFF',
        actionMonochromeBackground: '#171717',
        actionMonochromeBackgroundHover: '#F9F9F9',
        actionMonochromeBorder: 'transparent',
        actionMonochromeBorderHover: 'transparent',
        actionSecondaryWhiteText: '#171717',
        actionSecondaryWhiteTextHover: '#171717',
        actionSecondaryWhiteBackground: '#009fe3',
        actionSecondaryWhiteBackgroundHover: '#008FCC',
        actionSecondaryWhiteBorder: 'transparent',
        actionSecondaryWhiteBorderHover: 'transparent',
        actionSecondaryBlackText: '#171717',
        actionSecondaryBlackTextHover: '#171717',
        actionSecondaryBlackBackground: '#FFFFFF',
        actionSecondaryBlackBackgroundHover: '#F9F9F9',
        actionSecondaryBlackBorder: 'transparent',
        actionSecondaryBlackBorderHover: 'transparent',
        actionOutlineText: '#EFEFEF',
        actionOutlineTextHover: '#171717',
        actionOutlineBackground: 'transparent',
        actionOutlineBackgroundHover: '#EFEFEF',
        actionOutlineBorder: '#EFEFEF',
        actionOutlineBorderHover: '#EFEFEF',
        actionTextLink: '#b3b5b5',
        actionTextLinkHover: '#E5E5E5',
        actionTextLinkBorder: '#EFEFEF',
        actionHeadlineLink: '#FFFFFF',
        actionHeadlineLinkHover: '#F3F3F3',
        actionNavLink: '#EFEFEF',
        actionNavLinkBackground: '#171717',
        actionNavLinkHover: '#009fe3',
        actionNavLinkHoverBackground: 'transparent',
        actionNavLinkActive: '#009fe3',
        actionNavLinkActiveBackground: 'transparent',
        actionDisabledText: '#FFFFFF',
        actionDisabledBackground: '#d1d4d2',
        actionDisabledBackgroundHover: '#d1d4d2',
        actionDisabledBorder: '#d1d4d2',
        actionDisabledBorderHover: '#d1d4d2',
        actionRadioSquareBackground: '#FFFFFF',
        actionRadioSquareActiveBackground: '#000000',
        actionRadioSquareColor: '#000000',
        actionRadioSquareActiveColor: '#FFFFFF',
        textTitle: '#FFFFFF',
        textHeadline: '#FFFFFF',
        textSubHeadline: '#F9F9F9',
        textTitleOverlay: '#FFFFFF',
        textBody: '#FFFFFF',
        textCaption: '#F9F9F9',
        textPrice: '#F9F9F9',
        formInputLabelText: '#b3b5b5',
        formInputFocusLabelText: '#b3b5b5',
        formInputInputText: '#FFFFFF',
        formInputPlaceholderText: '#E3E3E3',
        formInputBorder: '#b3b5b5',
        formInputBorderFocus: '#b3b5b5',
        formInputBorderError: '#c23934',
        formInputCheckbox: '#21A153',
        formInputBackground: '#121212',
        formInputBackgroundSecondary: '#424547',
        formInputBorderSecondary: '#424547',
        formInputBackgroundDisabled: '#424547',
        formRadioEnabled: '#171717',
        formRadioDisabled: '#d1d4d2',
        formRadioBorderDisabled: '#E3E3E3',
        formRadioBorderEnabled: '#d1d4d2',
        formRadioBorderActive: '#171717',
        formListItemBackground: '#1E1E1E',
        formListItemBackgroundHover: '#1E1E1E',
        formSliderVerticalBars: '#d1d4d2',
        formSliderHandleBackground: '#FFFFFF',
        formSliderHandleBorder: '#d1d4d2',
        formSliderProgressBar: '#34428A',
        formStatusErrorLight: '#FEF3F2',
        formStatusErrorMain: '#c23934',
        formStatusErrorDark: '#AE201A',
        formStatusSuccessLight: '#F0FDF5',
        formStatusSuccessMain: '#21A153',
        formStatusSuccessDark: '#21A153',
        formStatusWarningLight: '#FEFCE9',
        formStatusWarningMain: '#ffb75d',
        formStatusWarningDark: '#975A12',
        formStatusInfoLight: '#EFF6FF',
        formStatusInfoMain: '#0073BE',
        formStatusInfoDark: '#1244CA',
        headerBackground: '#171717',
        headerStickyBorderBottom: 'unset',
        headerStickyBoxShadow: 'unset',
        headerStickyBoxShadowNoNotification: 'unset',
        headerStickyBackground: '#171717',
        headerStickyBackgroundScrolled: '#171717',
        headerStickyBorderBottomScrolled: '#171717',
        headerSearchInputBorder: '#d1d4d2',
        headerMegamenu: '#F9F9F9',
        headerPlpSidebarTitle: '#FFFFFF',
        footerBackground: '#171717',
        footerTextColor: '#FFFFFF',
        footerHeadingColor: '',
        tableThead: '#F9F9F9',
        tableTh: '#F9F9F9',
        tableTd: '#121212',
        iconPrimary: '#FFFFFF',
        iconSecondary: '#888c8d',
        iconTertiary: '#FFFFFF',
        iconLoader: '#FFFFFF',
        iconRoundedBackground: '#181818',
        iconRoundedIcon: '#FFFFFF',
        iconRoundedBorder: '#FFFFFF',
        iconRoundedActiveBackground: '#FFFFFF',
        iconRoundedActiveIcon: '#181818',
        iconRoundedActiveBorder: '#FFFFFF',
        logo: '#FFFFFF',
        borderDivider1: '#424547',
        borderDivider2: '#E3E3E3',
        pillActiveTextColor: '#181818',
        pillActiveTextColorHover: '#181818',
        pillActiveBorderColor: '#FFFFFF',
        pillActiveBorderColorHover: '#FFFFFF',
        pillActiveBackground: '#FFFFFF',
        pillActiveBackgroundHover: '#FFFFFF',
        pillDefaultTextColor: '#FFFFFF',
        pillDefaultTextColorHover: '#171717',
        pillDefaultBorderColor: '#EFEFEF',
        pillDefaultBorderColorHover: 'undefined',
        pillDefaultBackground: '#171717',
        pillDefaultBackgroundHover: '#EFEFEF',
        pillBadgeBackground: '#FFFFFF',
        pillBadgeBorderColorHover: '#171717',
        wrapperBody: '#171717',
        wrapperPublication: 'undefined',
        wrapperCategory: 'undefined',
        wrapperProduct: '#171717',
        wrapperTileColor: 'undefined',
        wrapperTileColorHover: 'undefined',
        wrapperSection: '#171717',
        wrapperBodyHover: '#1E1E1E',
        wrapperWell: '#171717',
        wrapperCard: '#424547',
        wrapperCardHover: '#424547',
        wrapperCardGradientColor: '#181818',
        wrapperCardGradientColorHover: '#121212',
        wrapperCardBorderColor: 'transparent',
        wrapperModal: '#181818',
        wrapperBanner: '#FFFFFF',
        wrapperBannerBorder: '#FFFFFF',
        wrapperUsp: '#009fe3',
        wrapperUspColor: '#EFEFEF',
        oBikeComparisonCompareButtonBackground: '#F8F8F8',
        oBikeComparisonCompareButtonBorderColor: '#d1d4d2',
        uListItemHighlightColor: '#FFFFFF',
        uListItemTextColorHover: '#424547',
        uCarouselBackgroundColor: '#FFFFFF',
        uPullUpToggleBackground: '#171717',
        uNewsletterTitleColor: '#EFEFEF',
        uKeyFeatureListBackgroundColor: 'unset',
        fallbackWhite: '#FFFFFF',
        fallbackGrey50: '#F9F9F9',
        fallbackGrey100: '#F3F3F3',
        fallbackGrey200: '#E3E3E3',
        fallbackGrey300: '#d1d4d2',
        fallbackGrey400: '#b3b5b5',
        fallbackGrey500: '#888c8d',
        fallbackGrey600: '#626667',
        fallbackGrey700: '#424547',
        fallbackGrey750: '#1E1E1E',
        fallbackGrey800: '#181818',
        fallbackGrey900: '#121212',
        fallbackBlack: '#171717',
    },
};
